import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import LoginSignUp from './LoginSignUp';
import ProfileForm from './ProfileForm';
import CreateAlertForm from './CreateAlertForm';
import TripDetails from './TripDetails';
import Alerts from './Alerts';
import ShieldWithTick from './ShieldWithTick';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Dashboard from './dashboard';
import AdminProtectedRoute from './AdminProtectedRoute';
import './App.css';

function App() {
  const [cookies, setCookie] = useCookies(['userId']);
  const [userId, setUserId] = useState('');
  useEffect(() => {
    const userIdCookie = cookies.userId;
    const localUserId = localStorage.getItem('userId');
    if (userIdCookie) {
      setUserId(userIdCookie);
    } else if (localUserId) {
      setUserId(localUserId);
      setCookie('userId', localUserId, { path: '/' });
    }
  }, [cookies, setCookie]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            userId ? <Alerts /> : <LoginSignUp />
          }
        />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/login" element={<LoginSignUp />} />
        <Route path="/ProfileForm" element={<ProfileForm />} />
        <Route path="/CreateAlertForm" element={<CreateAlertForm />} />
        <Route path="/share/:Id" element={<TripDetails />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route 
          path="/dashboard" 
          element={
            <AdminProtectedRoute>
              <Dashboard />
            </AdminProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;