// AdminProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import ClipLoader from "react-spinners/ClipLoader";

const AdminProtectedRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const userId = localStorage.getItem("userId");
      
      if (!userId) {
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "UserTable", userId));
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().isAdmin === true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(8px)',
          zIndex: 9999,
        }}
      >
        <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
      </div>
    );
  }

  if (!isAdmin) {
    return <Navigate to="/alerts" replace />;
  }

  return children;
};

export default AdminProtectedRoute;